import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

/** The custom theme for the application. */
const theme = createTheme({
  caution: {
    main: "#f9e534",
  },
  palette: {
    error: {
      main: red.A400,
    },
    primary: {
      main: "#1770f6",
    },
    secondary: {
      main: "#19857b",
    },
    success: {
      main: "#126d27",
    },
    warning: {
      main: "#ff9800",
    },
  },
  typography: {
    fontFamily: [
      "nunito",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
    ].join(","),
  },
});

declare module "@mui/material/styles" {
  interface Theme {
    caution: {
      main: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    caution?: {
      main?: string;
    };
  }
}

export default theme;
