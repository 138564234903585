/* eslint-disable no-irregular-whitespace */
import { Grid, Link, Typography } from "@mui/material";

function Privacy() {
  const textSecondaryColor = "rgba(0, 0, 0, 0.6)";
  return (
    <Grid container spacing={4} sx={{ p: 3 }}>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Privacy Policy</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          At Bluebird, accessible from bluebird.io, one of our main priorities
          is the privacy of our visitors. This Privacy Policy document contains
          types of information that is collected and recorded by Bluebird and
          how we use it. If you have additional questions or require more
          information about our Privacy Policy, do not hesitate to contact us.
          This Privacy Policy applies only to our online activities and is valid
          for visitors to our website with regards to the information that they
          shared and/or collect in Bluebird. This policy is not applicable to
          any information collected offline or via channels other than this
          website. Our Privacy Policy was created with the help of the Free
          Privacy Policy Generator.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Consent</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          By using our website, you hereby consent to our Privacy Policy and
          agree to its terms.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Information we collect</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          The personal information that you are asked to provide, and the
          reasons why you are asked to provide it, will be made clear to you at
          the point we ask you to provide your personal information. If you
          contact us directly, we may receive additional information about you
          such as your name, email address, phone number, the contents of the
          message and/or attachments you may send us, and any other information
          you may choose to provide. When you register for an Account, we may
          ask for your contact information, including items such as name,
          company name, address, email address, and telephone number.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">How we use your information</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          We use the information we collect in various ways, including to:
        </Typography>
        <ul>
          <li style={{ color: textSecondaryColor }}>
            <Typography variant="subtitle1" color="text.secondary">
              Provide, operate, and maintain our website
            </Typography>
          </li>
          <li style={{ color: textSecondaryColor }}>
            <Typography variant="subtitle1" color="text.secondary">
              Improve, personalize, and expand our website
            </Typography>
          </li>
          <li style={{ color: textSecondaryColor }}>
            <Typography variant="subtitle1" color="text.secondary">
              Understand and analyze how you use our website
            </Typography>
          </li>
          <li style={{ color: textSecondaryColor }}>
            <Typography variant="subtitle1" color="text.secondary">
              Develop new products, services, features, and functionality
            </Typography>
          </li>
          <li style={{ color: textSecondaryColor }}>
            <Typography variant="subtitle1" color="text.secondary">
              Communicate with you, either directly or through one of our
              partners, including for customer service, to provide you with
              updates and other information relating to the website, and for
              marketing and promotional purposes
            </Typography>
          </li>
          <li style={{ color: textSecondaryColor }}>
            <Typography variant="subtitle1" color="text.secondary">
              Send you emails
            </Typography>
          </li>
          <li style={{ color: textSecondaryColor }}>
            <Typography variant="subtitle1" color="text.secondary">
              Find and prevent fraud
            </Typography>
          </li>
        </ul>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Log Files</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Bluebird follows a standard procedure of using log files. These files
          log visitors when they visit websites. All hosting companies do this
          and a part of hosting services&#39; analytics. The information
          collected by log files include internet protocol (IP) addresses,
          browser type, Internet Service Provider (ISP), date and time stamp,
          referring/exit pages, and possibly the number of clicks. These are not
          linked to any information that is personally identifiable. The purpose
          of the information is for analyzing trends, administering the site,
          tracking users&#39; movement on the website, and gathering demographic
          information.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Cookies and Web Beacons</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Like any other website, Bluebird uses &#39;cookies&#39;. These cookies
          are used to store information including visitors&#39; preferences, and
          the pages on the website that the visitor accessed or visited. The
          information is used to optimize the users&#39; experience by
          customizing our web page content based on visitors&#39; browser type
          and/or other information. For more general information on cookies,
          please read &quot;Cookies&quot; article from the Privacy Policy
          Generator.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Our Advertising Partners</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Some of advertisers on our site may use cookies and web beacons. Our
          advertising partners are listed below. Each of our advertising
          partners has their own Privacy Policy for their policies on user data.
          For easier access, we hyperlinked to their Privacy Policies below.
        </Typography>
        <ul>
          <li style={{ color: textSecondaryColor }}>
            <Typography variant="subtitle1" color="text.secondary">
              <Link
                color="secondary"
                href="https://policies.google.com/technologies/ads"
              >
                Google
              </Link>
            </Typography>
          </li>
        </ul>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">
          Advertising Partners Privacy Policies
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          You may consult this list to find the Privacy Policy for each of the
          advertising partners of Bluebird.
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Third-party ad servers or ad networks uses technologies like cookies,
          JavaScript, or Web Beacons that are used in their respective
          advertisements and links that appear on Bluebird, which are sent
          directly to users&#39; browser. They automatically receive your IP
          address when this occurs. These technologies are used to measure the
          effectiveness of their advertising campaigns and/or to personalize the
          advertising content that you see on websites that you visit.
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Note that Bluebird has no access to or control over these cookies that
          are used by third-party advertisers.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Third Party Privacy Policies</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Bluebird&#39;s Privacy Policy does not apply to other advertisers or
          websites. Thus, we are advising you to consult the respective Privacy
          Policies of these third-party ad servers for more detailed
          information. It may include their practices and instructions about how
          to opt-out of certain options.
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management
          with specific web browsers, it can be found at the browsers&#39;
          respective websites.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">
          CCPA Privacy Rights (Do Not Sell My Personal Information)
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Under the CCPA, among other rights, California consumers have the
          right to:
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Request that a business that collects a consumer&#39;s personal data
          disclose the categories and specific pieces of personal data that a
          business has collected about consumers.
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Request that a business delete any personal data about the consumer
          that a business has collected.
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Request that a business that sells a consumer&#39;s personal data, not
          sell the consumer&#39;s personal data.
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">GDPR Data Protection Rights</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          We would like to make sure you are fully aware of all of your data
          protection rights. Every user is entitled to the following:
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          The right to access – You have the right to request copies of your
          personal data. We may charge you a small fee for this service.
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          The right to rectification – You have the right to request that we
          correct any information you believe is inaccurate. You also have the
          right to request that we complete the information you believe is
          incomplete.
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          The right to erasure – You have the right to request that we erase
          your personal data, under certain conditions.
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          The right to restrict processing – You have the right to request that
          we restrict the processing of your personal data, under certain
          conditions.
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          The right to object to processing – You have the right to object to
          our processing of your personal data, under certain conditions.
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          The right to data portability – You have the right to request that we
          transfer the data that we have collected to another organization, or
          directly to you, under certain conditions.
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Children&#39;s Information</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Bluebird does not knowingly collect any Personal Identifiable
          Information from children under the age of 13. If you think that your
          child provided this kind of information on our website, we strongly
          encourage you to contact us immediately and we will do our best
          efforts to promptly remove such information from our records.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Privacy;
