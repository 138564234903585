/* eslint-disable no-irregular-whitespace */
import { Grid, Link, Typography } from "@mui/material";

function Terms() {
  const textSecondaryColor = "rgba(0, 0, 0, 0.6)";
  return (
    <Grid container spacing={4} sx={{ p: 3 }}>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Terms and conditions</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          These terms and conditions (“Agreement”) set forth the general terms
          and conditions of your use of the “Bluebird” mobile application,
          internet application, or website (“Mobile Application” or “Service”)
          and any of its related products and services (collectively,
          “Services”). This Agreement is legally binding between you (“User”,
          “you” or “your”) and Bluebirding, Inc. and all of its related entities
          (“Operator”, “we”, “us” or “our”). If you are entering into this
          agreement on behalf of a business or other legal entity, you represent
          that you have the authority to bind such entity to this agreement, in
          which case the terms “User”, “you” or “your” shall refer to such
          entity and any or all of its related entities. If you do not have such
          authority, or if you do not agree with the terms of this agreement,
          you must not accept this agreement and may not access and use the
          Mobile Application and Services. By accessing and using the Mobile
          Application and Services, you acknowledge and agree that you have
          read, understood, and agree to be bound by the terms of this Agreement
          and our Privacy Policy, which is fully incorporated by reference into
          this Agreement. Under this Agreement, “use“ or “access“ of the Mobile
          Application or Services specifically includes any direct or indirect
          access or use of the Mobile Application or Services or any cached
          version of them and any direct or indirect access or use of any
          information or content on them, regardless of how obtained and the
          terms Mobile Application, Service, and Services includes, without
          limitation, any cached version thereof. You acknowledge that this
          Agreement is a binding contract between you and the Operator, even
          though it is electronic and is not physically signed by you, and it
          governs your use of the Mobile Application and Services.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Accounts and membership</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          You must be at least 18 years of age to use the Mobile Application and
          Services. By using the Mobile Application and Services and by agreeing
          to this Agreement you warrant and represent that you are at least 18
          years of age. If you create an account in the Mobile Application, you
          are responsible for maintaining the security of your account and you
          are fully responsible for all activities that occur under the account
          and any other actions taken in connection with it. We may, but have no
          obligation to, monitor and review new or existing accounts before you
          may sign in and start using the Services. Providing false contact
          information of any kind may result in the termination of your account.
          You must immediately notify us of any unauthorized uses of your
          account or any other breaches of security. We will not be liable for
          any acts or omissions by you, your agents, assigns, representatives,
          or any others with your express or implied permission or
          authorization, including any damages, expenses, claims, costs, awards,
          liabilities, or penalties of any kind incurred as a result of such
          acts or omissions. We may suspend, disable, or delete your account (or
          any part thereof) either temporarily or permanently if we determine
          that you have violated any provision of this Agreement or that your
          conduct or content would tend to damage us, our reputation, goodwill,
          or ability to conduct our business or provide Services as we see fit
          or appropriate. If we delete your account for the foregoing reasons,
          you may not re-register for our Services. We may block your email
          address and Internet protocol address or take any other steps to
          prevent your further or future registration or use of the Services.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">User content</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          We do not own any data, information, or material (collectively,
          “Content”) that you or others submit in the Mobile Application in the
          course of using the Service. You shall have sole responsibility for
          the accuracy, quality, integrity, legality, reliability,
          appropriateness, and intellectual property ownership or right to use
          of all submitted Content. We may monitor and review the Content in the
          Mobile Application submitted or created using our Services by you. You
          grant us permission to access, copy, distribute, store, transmit,
          reformat, display and perform the Content of your user account as
          required for the purpose of providing the Services to you or for any
          other purpose not inconsistent with the law, including but not limited
          to the promotion, commercializing, marketing, sharing, reviewing,
          improving, offering of us, the Mobile Application, or Services.
          Without limiting any of those representations or warranties, we have
          the right, though not the obligation, to, in our own sole discretion,
          refuse or remove any Content that, in our reasonable opinion, violates
          any of our policies or is in any way harmful or objectionable. You
          also grant us the unlimited and permanent license to use, reproduce,
          adapt, modify, publish or distribute the Content created by you or
          stored in your user account for commercial, marketing or any similar
          or other purpose.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Transactions between Users</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          We urge you and all users to be responsible about their use of the
          Services and any transaction entered into as a result of either
          listing a property or renting a property. We do not own or manage, nor
          can we contract for, any vacation rental property listed on the
          Services. The Services may provide an on-line marketplace to allow
          property owners, managers, or authorized representatives who advertise
          on the Services (each, a “member”) to offer for rent in a variety of
          pricing formats, a specific rental property to potential renters or
          renters (each, a “traveler” and, collectively with a member, the
          “users”). “Members“ may also include property owners or managers who
          originally advertised their properties on another website or service
          and their listings have been redistributed on the Services. We also
          may offer online booking or other tools or services to allow users to
          communicate with each other and enter into rental agreements or other
          transactions. We are not a party to any rental or other agreement
          between users. This is true even if the Services allow you to book a
          rental or provides other ancillary products or services, as the
          Services may facilitate booking a rental or other tools, services or
          products, but we are not a party to any rental or other agreement
          between users. As a result, any part of an actual or potential
          transaction between a traveler and a member or between users,
          including the Content, quality, condition, safety or legality of the
          properties advertised, the truth or accuracy of the listings
          (including the content thereof or any review relating to any traveler
          or property), the ability of members to rent, use, or access a
          property or the ability of travelers to contract for properties are
          solely the responsibility of each user. You acknowledge and agree that
          you may be required to enter into one or more separate agreements,
          waivers or terms and conditions before making a booking or purchasing
          a product or service and we or one of our affiliates may place
          additional restrictions on your booking, product or service. You and
          users agree that we are not responsibility or liable in any way, to
          the greatest extent permitted by applicable law, for any transaction,
          agreement, Content, or other action, statement, or offering, made,
          posted, or acted on by you or any other member using the Services. You
          and users agree that you and they are solely responsible for, and
          agree to abide by, compliance with all laws, rules and regulations
          applicable to their use of the Services, their use of any tool,
          service or product offered on the Services and any transaction they
          enter into on the Services or in connection with their use of the
          Services. As such, you and users agree that we are not responsible or
          liable in any way for you or users’ compliance with any such laws,
          rules, or regulation, or any such access or use. You and other Members
          further agree that they are solely responsible for and agree to abide
          by all laws, rules, ordinances, or regulations applicable to the
          listing of their property and the conduct of any rental business,
          including but not limited to any and all laws, rules, ordinances,
          regulations or other requirements relating to taxes, credit cards,
          data and privacy, permits or license requirements, zoning ordinances,
          safety compliance and compliance with all anti-discrimination and fair
          housing laws, as applicable. As such, you and users agree that we are
          not responsible or liable in any way for you or users’ compliance.
          Please be aware that, even though we are not a party to any rental
          transaction and assume no liability for legal or regulatory compliance
          pertaining to rental properties listed on the Services, there may be
          circumstances where we are nevertheless legally obligated (as we may
          determine in our sole discretion) to provide information relating to
          your listing in order to comply with requests from governmental bodies
          in relation to investigations, litigation or administrative
          proceedings, and we may choose to comply with such obligations in our
          sole discretion. Members who accept credit card, banking, or other
          payment information from travelers agree to properly handle and
          safeguard all such information in accordance with applicable legal and
          regulatory requirements and best practices. Although most travel is
          completed without a serious incident, travel to some destinations may
          involve more risk than others. We urge travelers to research the
          location they wish to visit and to review travel prohibitions,
          warnings, announcements and advisories issued by the United States
          Government before booking. Information may be found at{" "}
          <Link color="secondary" href="www.state.gov">
            www.state.gov
          </Link>
          ,{" "}
          <Link color="secondary" href="www.tsa.gov">
            www.tsa.gov
          </Link>
          ,{" "}
          <Link color="secondary" href="www.dot.gov">
            www.dot.gov
          </Link>
          ,{" "}
          <Link color="secondary" href="www.faa.gov">
            www.faa.gov
          </Link>
          ,{" "}
          <Link color="secondary" href="www.cdc.gov">
            www.cdc.gov
          </Link>
          ,{" "}
          <Link color="secondary" href="www.treas.gov/ofac">
            www.treas.gov/ofac
          </Link>{" "}
          and{" "}
          <Link color="secondary" href="www.customs.gov">
            www.customs.gov
          </Link>
          . While we do take certain measures with a goal to assist users to
          avoid potentially fraudulent or other illegal activity of which we
          become aware, You understand that we have no obligation to, and we are
          not liable in any way related to, take or not take any measures or
          actions with respect to potentially fraudulent or illegally activity,
          and that you assume the risk of any such activity. However, we may
          opt, at our own and absolute discretion to provide some warning or
          take some action with respect to such activity. If we provide warnings
          or messages to users about any such activity, we do not make any
          representations, warranties, or covenants that such messages are
          accurate or that such messages will reach any or all users they should
          have reached in a timely manner or at all or that such messages or
          measures will prevent any harm or otherwise have any impact.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">
          Limited License to Use the Services
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Users are granted a limited, revocable non-exclusive license to access
          the Services and the Content provided on the Services solely for the
          purpose of advertising a property, searching for a property,
          purchasing or researching (for the purpose of inquiring about
          purchasing) any of the products or services offered on any Services,
          participating in an interactive area hosted on the Services or for any
          other purpose clearly stated on a Services, all in accordance with the
          Terms. Any use of the Services that is not for one of these purposes
          or otherwise in accordance with the Terms or as otherwise authorized
          by us in writing is expressly prohibited.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Prohibited uses</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          In addition to other terms as set forth in the Agreement, you are
          prohibited from using the Mobile Application and Services or Content:
          (a) for any unlawful purpose; (b) to solicit others to perform or
          participate in any unlawful acts; (c) to violate any international,
          federal, provincial or state regulations, rules, laws, or local
          ordinances; (d) to infringe upon or violate our intellectual property
          rights or the intellectual property rights of others; (e) to harass,
          abuse, insult, harm, defame, slander, disparage, intimidate, or
          discriminate based on gender, sexual orientation, religion, ethnicity,
          race, age, national origin, disability, or any other protected
          characteristic; (f) to submit false or misleading information; (g) to
          upload or transmit viruses or any other type of malicious code that
          will or may be used in any way that will affect the functionality or
          operation of the Mobile Application or Services, third party products
          and services, the Content, or the Internet; (h) to spam, phish, pharm,
          pretext, spider, crawl, or scrape; (i) for any obscene, unethical, or
          immoral purpose; or (j) to interfere with or circumvent the security
          features of the Mobile Application and Services, third party products
          and services, or the Internet. We reserve the right to terminate your
          use of the Mobile Application and Services for violating any of the
          prohibited uses or as otherwise provided in this Agreement.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Unauthorized Uses of the Services</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          The license to use the Services only extends to the uses expressly
          described herein. The license to use the Services granted to users in
          this Agreement does not include any right of collection, aggregation,
          copying, scraping, duplication, display or any derivative use of the
          Services nor any right of use of data mining, robots, spiders or
          similar data gathering and extraction tools without our prior written
          permission; provided, however, that a limited exception from the
          foregoing exclusion is provided to general purpose internet search
          engines that use tools to gather information for the sole purpose of
          displaying hyperlinks to the Services, provided they each do so from a
          stable IP address or range of IP addresses using an easily
          identifiable agent and comply with our robots.txt file. “General
          purpose internet search engines” do not include a website or search
          engine or other service that provides classified listings or property
          rental advertisements, or any subset of the same or which is in the
          business of providing property rental services or other services that
          compete with us. Unauthorized uses of the Services also include,
          without limitation, those listed below. You agree not to do, or assist
          or facilitate in any action that furthers or permits, any of the
          following, unless otherwise previously and specifically agreed to by
          us:
          <ul>
            <li style={{ color: textSecondaryColor }}>
              <Typography variant="subtitle1" color="text.secondary">
                Any commercial use of the Services or any content on the
                Services, other than by members in good standing, or by members
                under a valid license to software offered on the Services;
              </Typography>
            </li>
            <li style={{ color: textSecondaryColor }}>
              <Typography variant="subtitle1" color="text.secondary">
                Any use of the Services or the tools and services on the
                Services for the purpose of booking or soliciting a rental of a
                property other than a property listed under a valid subscription
                or pay-per-booking product;
              </Typography>
            </li>
            <li style={{ color: textSecondaryColor }}>
              <Typography variant="subtitle1" color="text.secondary">
                Copy, reproduce, upload, post, display, republish, distribute or
                transmit any part of the content in any form whatsoever;
              </Typography>
            </li>
            <li style={{ color: textSecondaryColor }}>
              <Typography variant="subtitle1" color="text.secondary">
                Reproduce any portion of the Services on your website or
                otherwise, using any device including, but not limited to, use
                of a frame or border environment around the Services, or other
                framing technique to enclose any portion or aspect of the
                Services, or mirror or replicate any portion of the Services;
              </Typography>
            </li>
            <li style={{ color: textSecondaryColor }}>
              <Typography variant="subtitle1" color="text.secondary">
                Deep-link to any portion of the Services without our express
                written permission;
              </Typography>
            </li>
            <li style={{ color: textSecondaryColor }}>
              <Typography variant="subtitle1" color="text.secondary">
                Modify, translate into any language or computer language or
                create derivative works from, any content or any part of the
                Services;
              </Typography>
            </li>
            <li style={{ color: textSecondaryColor }}>
              <Typography variant="subtitle1" color="text.secondary">
                Reverse engineer any part of the Services;
              </Typography>
            </li>
            <li style={{ color: textSecondaryColor }}>
              <Typography variant="subtitle1" color="text.secondary">
                Sell, offer for sale, transfer or license any portion of the
                Services in any form to any third parties;
              </Typography>
            </li>
            <li style={{ color: textSecondaryColor }}>
              <Typography variant="subtitle1" color="text.secondary">
                Use the Services and its inquiry or booking functionality other
                than to advertise and/or research vacation rentals, to make
                legitimate inquiries to our members or any other use expressly
                authorized on the Services;
              </Typography>
            </li>
            <li style={{ color: textSecondaryColor }}>
              <Typography variant="subtitle1" color="text.secondary">
                Use the Services to post or transmit information that is in any
                way false, fraudulent, or misleading, including making any
                reservation or inquiry under false pretenses, or taking any
                action that may be considered phishing or that would give rise
                to criminal or civil liability;
              </Typography>
            </li>
            <li style={{ color: textSecondaryColor }}>
              <Typography variant="subtitle1" color="text.secondary">
                Post or transmit any unlawful, threatening, abusive, libelous,
                defamatory, obscene, vulgar, indecent, inflammatory, sexually
                explicit, pornographic or profane material;
              </Typography>
            </li>
            <li style={{ color: textSecondaryColor }}>
              <Typography variant="subtitle1" color="text.secondary">
                Violate, plagiarize or infringe the rights of us or third
                parties including, without limitation, copyright, trademark,
                patent, trade secrets, rights of publicity or privacy or any
                other intellectual or proprietary rights; or
              </Typography>
            </li>
            <li style={{ color: textSecondaryColor }}>
              <Typography variant="subtitle1" color="text.secondary">
                Use or access the Services in any way that, in our sole
                discretion, adversely affects, or could adversely affect, the
                performance or function of the Services or any other system used
                by us or the Services.
              </Typography>
            </li>
          </ul>
          If you are aware of, or experience, any Content, activity, or
          communication through, or in connection with, the Services that
          appears to be in violation of the above restrictions, or in violation
          of any other provision of these Terms, you agree that you must that
          inform us by contacting us as set forth under “Contact Us,” accessible
          on our website.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">
          Proprietary Rights and Downloading of Information from the Services
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          The Services and all content and information on the Services are
          protected by copyright as a collective work and/or compilation, under
          applicable U.S. and international copyright laws and conventions and
          database rights. You agree to abide by any and all copyright notices,
          information or restrictions contained in or relating to any content on
          the Services. Copying, storing or otherwise accessing the Services or
          any content on the Services other than for your personal,
          noncommercial use (other than in accordance with a valid listing) is
          expressly prohibited without prior written permission from us. As part
          of the rental inquiry or reservation process, for your own personal,
          noncommercial use and not for further distribution, you may download,
          display and/or print one copy of any portion of the Services. You may
          not modify the same, and you must reproduce our copyright notice in
          the form displayed on the relevant portion(s) of the Services that you
          desire to download, display or print.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">
          Your E-mail Address and Data; Our Privacy Policy; Data Transmittal
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          When you provide your e-mail address, name or other information to us
          in connection with your use or access to the Services, any service or
          tool provided on the Services or otherwise, you agree to allow us, our
          affiliates, agents, assigns, and related entities and the Services, to
          add your e-mail address, name or other information provided to our or
          their database of users. You may receive one or more promotional or
          other e-mails from us or any such entity. You are welcome to opt not
          to receive such promotional e-mails from us or such entities at any
          time. Please review our Privacy Policy for more information regarding
          our email and other data collection practices and safeguards, and how
          to opt not to receive such emails. Your use of the Services signifies
          your acknowledgment of, and agreement with, our Privacy Policy. We
          adhere to strong principles of privacy. You agree that we may access
          and use your Content in accordance with this Agreement and/or
          our Privacy Policy.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography>Identity Verification</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          User verification on the Internet is difficult and we cannot, and do
          not assume any responsibility for, the confirmation of each user's
          purported identity. We encourage you to communicate directly with a
          traveler or member through the tools available on the Services, though
          even this does not assure you of the identity of the person with which
          you are communicating. We further encourage you to take other
          reasonable measures to assure yourself of the other person’s identity
          and, for travelers, of the property and relevant details of your
          booking or proposed booking. You agree to (i) keep your password and
          online ID for both your account with us and your email account secure
          and strictly confidential, providing it only to authorized users of
          your accounts, (ii) instruct each person to whom you give your online
          ID and password that he or she is not to disclose it to any
          unauthorized person, (iii) notify us immediately and select a new
          online ID and password if you believe your password for either your
          account with us or your email account may have become known to an
          unauthorized person, and (iv) notify us immediately if you are
          contacted by anyone requesting your online ID and password. Further,
          if we suspect any unauthorized access to your account, upon our
          request, you agree to promptly change your ID and password and take
          any other related action as we may reasonably request. Additionally,
          if we suspect any unauthorized access to your account, you agree that
          we may take any action we deem appropriate or necessary, as determine
          by us within our sole discretion, that includes but is not limited to,
          temporarily or permanently suspending or terminating your account,
          membership, or access or use of the Services. We may also, without
          notice to you, suspend or cancel your listing at any time even without
          receiving notice from you if we suspect, in our sole discretion, that
          your account with us or your email account is being used in an
          unauthorized or fraudulent manner or manner inconsistent with this
          Agreement. We discourage you from giving anyone access to your online
          ID and password for your account with us and your email account.
          However, if you do give someone your online ID and online password, or
          if you fail to adequately safeguard such information, you are
          responsible for any and all transactions that the person performs
          while using your account with us and/or your email account, even those
          transactions that are fraudulent or that you did not intend or want
          performed. EACH USER ACKNOWLEDGES AND AGREES THAT: (1) NEITHER US NOR
          ANY OF OUR AFFILIATES, ASSIGNS, OR RELATED ENTITIES, WILL HAVE ANY
          LIABILITY TO ANY USER FOR ANY UNAUTHORIZED TRANSACTION MADE USING ANY
          USER’S ID OR PASSWORD; AND (2) THE UNAUTHORIZED USE OF YOUR ONLINE ID
          AND PASSWORD FOR YOUR ACCOUNT OR YOUR EMAIL ACCOUNT COULD CAUSE YOU TO
          INCUR LIABILITY TO BOTH US AND OTHER USERS.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">
          Limitations on Communications and Use of Other Users’ Information; No
          Spam
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          You agree that, with respect to other users' personal information that
          you obtain directly or indirectly from or through the Services or
          through any Services-related communication, transaction or software,
          we have granted to you a license to use such information only for: (i)
          Services-related communications that are not unsolicited commercial
          messages, (ii) using services offered through the Services, and (iii)
          inquiring about or otherwise facilitating a financial transaction
          between you and the other user related to the purpose of the Services
          (such as inquiring about or booking a property or charging a personal
          credit card). Any other purpose will require express permission from
          the user. You may not use any such information for any unlawful
          purpose or with any unlawful intent. In all cases, you must give users
          an opportunity to remove their information from your address book or
          database or other records and a chance to review what information you
          have collected about them. In addition, under no circumstances, except
          as defined in this provision, may you disclose personal information
          about another user to any third party without the consent of the other
          user. You agree that other users may use your personal information to
          communicate with you in accordance with this provision. Further, you
          agree that you will protect other users’ personal information with the
          same degree of care that you protect your own confidential information
          (using at minimum a reasonable standard of care), and you assume all
          liability for the misuse, loss or unauthorized transfer of such
          information. You understand and agree that we are in no way
          responsible or liable in any way for such misuse, lose, or
          unauthorized transfer. We do not tolerate spam or unsolicited
          commercial electronic communications of any kind. Therefore, without
          limiting the foregoing, you are not licensed to add a Services user,
          even a user who has performed a transaction with you, to your mailing
          list (email or physical mail) without the user's express consent. You
          may not use any tool or service on the Services to send spam or
          unsolicited commercial electronic communications of any kind or in any
          other way that would violate these Terms.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Fees</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          You understand and agree that we charge a service fee payable by
          travelers who book a property via the Services. Depending on the laws
          of the jurisdiction of the traveler and/or member, VAT may be charged
          on top of the service fee. The exact service fee (and any VAT, if
          applicable) charged will be displayed to travelers at the time of
          booking. The service fee plus applicable VAT will be charged after
          both the traveler and member accept the reservation. The service fee
          will only be refunded in the event a traveler is entitled to a refund
          of the entire rental amount under the terms of the cancellation policy
          presented in the listing. Any taxes alleged to be owed by any taxing
          authority on the service fee are the responsibility of us and members
          have no responsibility for any such claimed tax liability. However,
          any taxes alleged to be owed by any taxing authority on any other
          charge or fee received by any user is the responsibility of the
          respective users, and we shall not be, and are not, responsibility or
          liability for tracking, taking, remitting, handling, providing any
          notice concerning, or otherwise acting with respect to such taxes.
          Members agree not to encourage or advise a traveler to avoid or
          circumvent the service fee charged by us.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">
          Social Media or Third-Party Websites
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          If we or the Services offers a tool or service that allows us to
          access or use any profile or other information about you that you have
          provided to Facebook or another third-party website (each a “Social
          Media Site”) and you decide to use such tool or service, you
          acknowledge and agree that:
          <ul>
            <li style={{ color: textSecondaryColor }}>
              <Typography variant="subtitle1" color="text.secondary">
                The information or content that is a part of your Social Media
                Site profile, which you have designated as “public” (or a
                similar designation) (with such information or content and
                referred to herein as “
              </Typography>
            </li>
            <li style={{ color: textSecondaryColor }}>
              <Typography variant="subtitle1" color="text.secondary">
                The Social Media Content will be considered user-generated
                content under this Agreement and both you and we shall have the
                same rights and responsibilities as you that we have with
                respect to user-generated content under this Agreement;
              </Typography>
            </li>
            <li style={{ color: textSecondaryColor }}>
              <Typography variant="subtitle1" color="text.secondary">
                In the event that the Social Media Content was for any reason
                misclassified with a public or similar designation or is
                otherwise inaccurate or to which you do not agree with for any
                reason, you agree to work with the Social Media Site to make any
                changes or resolve any disputes and acknowledge that we will not
                be able to provide you with recourse; and
              </Typography>
            </li>
            <li style={{ color: textSecondaryColor }}>
              <Typography variant="subtitle1" color="text.secondary">
                The operation of your profile and account with and on the Social
                Media Site shall continue to be governed by the terms and
                conditions and privacy policy of such Social Media Site.
              </Typography>
            </li>
          </ul>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Notification of Infringement</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          We respect the intellectual property rights of others, and we do not
          permit, condone or tolerate the posting of any content on the Services
          that infringes any person's copyright. We will terminate, in
          appropriate circumstances, a member or traveler who is the source of
          repeat infringement of copyright.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Unsolicited Ideas and Feedback</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          From time to time, users submit to us ideas or suggestions pertaining
          to our business, such as ideas for new or improved products or
          technologies, website or tool enhancements, processes, materials,
          marketing plans or new product names. You understand that we are under
          no obligation to review or consider them. If you choose to submit any
          ideas, original creative artwork, suggestions or other works
          (“submissions”) in any form to us, then regardless of what you say,
          write, or provide to us in connection with your submissions, the
          following terms shall apply. The sole purpose of this policy is to
          avoid potential misunderstandings or disputes in the event that any
          part of our business, such as our products, websites, technologies or
          marketing strategies, seems similar to any of your submissions. If you
          provide any submissions to us, you agree that: (i) your submission and
          its contents will automatically become the property of us, without any
          compensation to you; (ii) we may use or redistribute, license, profit,
          commercialize, implement, or otherwise use any such submission and its
          contents for any purpose and in any way without any compensation,
          notice, or right owed to you; (iii) there is no obligation for us to
          review any submission; and (iv) there is no obligation to keep any
          submission confidential. We welcome your feedback regarding many areas
          of our business. If you want to send us your feedback, we simply
          request that you send it to us using the “feedback” tab located on the
          bottom right side of the homepage, search results pages, property
          pages, and the owner dashboard. Please provide only specific feedback
          on our Services. Keep in mind that we assume no obligation to keep any
          feedback you provide confidential and we reserve the right to use or
          disclose such information in any manner.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">
          Responsibility for Property and Traveler Liability
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          You understand and agree that you and users are solely responsible for
          obtaining insurance coverage sufficient to protect their properties,
          guests, their trip, or anything else, as applicable. You and members
          agree that they have or will obtain the appropriate insurance coverage
          sufficient to cover the rental, use, access, possession, or activity
          of, to, or on, any properties or activities they list via the Services
          at the time of such listing, and adequate insurance coverage shall be
          maintained through the latest of the time of any listing being removed
          or the latest departure date of any traveler they have obtained via
          the Services. Further, members agree to provide us with copies of
          relevant proof of coverage upon request.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Backups</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          We perform regular backups of the Content, however, these backups are
          for our own administrative purposes only and are in no way guaranteed.
          You are responsible for maintaining your own backups of your data. We
          do not provide any sort of compensation for lost or incomplete data in
          the event that backups are not performed or do not function properly.
          We will do our best to ensure complete and accurate backups, but you
          understand and agree that we assume no responsibility for this duty or
          any other duty related to the backups or storage of the Content, to
          the greatest extent compliant with the applicable law.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Links to other resources</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Although the Mobile Application and Services may link to other
          resources (such as website, mobile applications, etc.), we are not,
          directly or indirectly, implying any approval, representation,
          warranty, covenant, association, sponsorship, endorsement, or
          affiliation with any linked resource, unless specifically stated
          herein. Some of the links in the Mobile Application may be “affiliate
          links”. This means if you click on the link and purchase an item, the
          Operator will receive an affiliate commission. We are not responsible
          for examining or evaluating, and we do not make any representations,
          warranties, or covenants concerning the offerings of any businesses or
          individuals or the content of their resources, statements, or any
          other information or solicitations. We do not assume any
          responsibility or liability for the actions, products, services, and
          content of any other third parties. You should carefully review the
          legal statements and other conditions of use of any resource which you
          access through a link in the Mobile Application. Your linking to any
          other off-Services resources is at your own risk.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Intellectual property rights</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          “Intellectual Property Rights” means all present and future rights
          conferred by statute, common law or equity in or in relation to any
          copyright and related rights, trademarks, designs, patents,
          inventions, goodwill and the right to sue for passing off, rights to
          inventions, rights to use, and all other intellectual property rights,
          in each case whether registered or unregistered and including all
          applications and rights to apply for and be granted, rights to claim
          priority from, such rights and all similar or equivalent rights or
          forms of protection and any other results of intellectual activity
          which subsist or will subsist now or in the future in any part of the
          world. This Agreement does not transfer to you any intellectual
          property owned by the Operator or third parties, and all rights,
          titles, and interests in and to such property will remain (as between
          the parties) solely with the Operator. All trademarks, service marks,
          graphics and logos used in connection with the Mobile Application and
          Services, are trademarks or registered trademarks of the Operator or
          its licensors. Other trademarks, service marks, graphics and logos
          used in connection with the Mobile Application and Services may be the
          trademarks of other third parties. Your use of the Mobile Application
          and Services grants you no right or license to reproduce or otherwise
          use any of the Operator or third party trademarks.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Limitation of liability</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          To the fullest extent permitted by applicable law, in no event will
          the Operator, its affiliates, directors, officers, employees, agents,
          suppliers or licensors be liable to any person for any indirect,
          incidental, special, punitive, cover or consequential damages
          (including, without limitation, damages for lost profits, revenue,
          sales, goodwill, use of content, impact on business, business
          interruption, loss of anticipated savings, loss of business
          opportunity), liabilities, costs, expenses, or fees, however caused,
          under any theory of liability, including, without limitation,
          contract, tort, warranty, breach of statutory duty, negligence or
          otherwise, even if the liable party has been advised as to the
          possibility of such damages or could have foreseen such damages, for
          any reason, including any related to or concerning us, the Services,
          this Agreement, any breach of this Agreement by you or a third-party,
          use of, or access to, the Services, or any actual or attempted
          interactions or transactions with any other user. To the maximum
          extent permitted by applicable law, the aggregate liability, if any,
          of the Operator and its affiliates, officers, employees, agents,
          suppliers and licensors relating in any way to the Mobile Application,
          Services, or us will be limited to an amount greater of one dollar or
          any amounts actually paid in cash by you to the Operator for the prior
          one month period prior to the first event or occurrence giving rise to
          such liability. The limitations and exclusions also apply if this
          remedy does not fully compensate you for any losses or fails of its
          essential purpose.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Indemnification</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          You agree to indemnify and hold the Operator and its affiliates,
          directors, officers, employees, agents, suppliers and licensors
          harmless from and against any liabilities, losses, damages, expenses
          or costs, including reasonable attorneys’ fees, incurred in connection
          with or arising from any third party allegations, claims, actions,
          disputes, or demands asserted against any of them as a result of or
          relating to you, your Content, your use of the Mobile Application or
          Services or any willful, reckless, or negligent misconduct on your
          part.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Severability</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          All rights and restrictions contained in this Agreement may be
          exercised and shall be applicable and binding only to the extent that
          they do not violate any applicable laws and are intended to be limited
          to the extent necessary so that they will not render this Agreement
          illegal, invalid or unenforceable. If any provision or portion of any
          provision of this Agreement is determined by a Court of competent
          jurisdiction to be illegal, invalid, or unenforceable, it is the
          intention of the parties that the remaining provisions or portions
          thereof shall constitute their agreement with respect to the subject
          matter hereof, and all such remaining provisions or portions thereof
          shall remain in full force and effect.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Dispute resolution</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          The formation, interpretation, and performance of this Agreement and
          any disputes arising out of it shall be governed by the substantive
          and procedural laws of West Virginia, United States without regard to
          its rules on conflicts or choice of law and, to the extent applicable,
          the laws of United States. The exclusive jurisdiction and venue for
          actions related to the subject matter hereof shall be the courts
          located in West Virginia, United States, and you hereby submit to the
          personal jurisdiction of such courts.{" "}
          <Typography
            variant="subtitle1"
            color="text.secondary"
            sx={{ textDecoration: "underline", fontWeight: "bold" }}
          >
            YOU HEREBY WAIVE ANY RIGHT TO A JURY TRIAL IN ANY PROCEEDING ARISING
            OUT OF OR RELATED TO THIS AGREEMENT. YOU INTENTIONALLY, AND WITH
            FULL AND INFORMED KNOWLEDGE, IRREVOCABLY AGREE TO, AND HEREBY DO,
            WAIVE AND RELINQUISH ANY AND ALL RIGHTS TO ASSERT ANY CLAIMS AGAINST
            US, OR ANY OF OUR AFFILIATES, RELATED ENTITIES, DIRECTORS, OFFICERS,
            AGENTS, ASSIGNS, OR REPRESENTATIVES AS A REPRESENTATIVE, MEMBER IN
            ANY CLASS OR REPRESENTATIVE ACTION, OR TO PARTICIPATE IN ANY CLASS
            OR REPRESENTATIVE ACTION EXCEPT WHERE SUCH WAIVER IS EXPRESSLY
            PROHIBITED BY LAW. TO THE EXTENT EITHER PARTY IS PERMITTED BY LAW AS
            DETERMINED BY A COURT OF COMPETENT JURISDICTION TO PROCEED WITH A
            CLASS OR REPRESENTATIVE ACTION AGAINST US, OR ANY OF OUR AFFILIATES,
            RELATED ENTITIES, DIRECTORS, OFFICERS, AGENTS, ASSIGNS, OR
            REPRESENTATIVES, YOU AGREE THAT : (I) THE PREVAILING PARTY SHALL NOT
            BE ENTITLED TO RECOVER ATTORNEYS’ FEES OR COSTS ASSOCIATED WITH
            PURSUING THE CLASS OR REPRESENTATIVE ACTION (NOT WITHSTANDING ANY
            OTHER PROVISION IN THIS AGREEMENT); AND (II) THE PARTY WHO INITIATES
            OR PARTICIPATES AS A MEMBER OF THE CLASS WILL NOT SUBMIT A CLAIM OR
            OTHERWISE PARTICIPATE IN ANY RECOVERY SECURED THROUGH THE CLASS OR
            REPRESENTATIVE ACTION.
          </Typography>
          The United Nations Convention on Contracts for the International Sale
          of Goods does not apply to this Agreement.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Remedies</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Agree that we may seek from you, and you are liable for any damage,
          liability, fee, cost, penalty, expense, or any other harm that results
          directly or indirectly from your violation of this Agreement. In
          addition to seeking any and all relief in the form of damages, you
          agree that we shall be fully entitled to any and all additional relief
          at law, equity, statute, or as a court or adjudicative body of
          competent deems appropriate, including but not limited to any
          restraining order or injunction related to, arising from, or
          concerning any violation of this Agreement by you, your
          representatives, agents, or assigns.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Changes and amendments</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          We reserve the right to modify this Agreement or its terms related to
          the Mobile Application and Services at any time at our discretion.
          When we do, we will revise the updated date at the bottom of this
          page. We may also provide notice to you in other ways at our
          discretion, such as through the contact information you have provided.
          An updated version of this Agreement will be effective immediately
          upon the posting of the revised Agreement unless otherwise specified.
          Your continued use of the Mobile Application and Services after the
          effective date of the revised Agreement (or such other act specified
          at that time) will constitute your agreement and consent to those
          changes.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Acceptance of these terms</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          You acknowledge that you have read this Agreement and agree to all its
          terms and conditions. By accessing and using the Mobile Application
          and Services you agree to be bound by this Agreement. If you do not
          agree to abide by the terms of this Agreement, you are not authorized
          to access or use the Mobile Application and Services. This terms and
          conditions policy was created with assistance of the{" "}
          <Link
            color="secondary"
            href="https://www.websitepolicies.com/terms-and-conditions-generator"
          >
            terms and conditions generator
          </Link>
          .
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h6">Contacting us</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          If you have any questions, concerns, or complaints regarding this
          Agreement, we encourage you to contact us using the details below:
          info@bluebirding.io
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="subtitle1" color="text.secondary">
          This document was last updated on October 4, 2023
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Terms;
