import {
  CardMembershipOutlined,
  Close,
  EnhancedEncryptionOutlined,
  Groups2Outlined,
  MobileFriendlyOutlined,
  PaidOutlined,
  StarRate,
  WorkspacePremiumOutlined,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Slide,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { useEffect, useState } from "react";

import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import BecomeHostIphone from "./images/become-host-iphone.png";
import BluebirdingLogo from "./images/bluebirding-logo.svg";
import Book from "./images/book.png";
import BrandonFitzwater from "./images/brandon-fitzwater.png";
import DownloadAppleStore from "./images/download_apple_store.svg";
import DownloadPlayStore from "./images/download_play_store.svg";
import ExploreIphone from "./images/explore-iphone.png";
import FindLandIphone from "./images/find-land-iphone.png";
import Hero from "./images/hero.png";
import MaxGottlieb from "./images/max-gottlieb.png";
import OurStory from "./images/our-story.png";
import PeytonBallard from "./images/peyton-ballard.png";
import ReggieJohnson from "./images/reggie-johnson.png";
import Register from "./images/register.png";
import Search from "./images/search.png";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function App() {
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const onlyLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const [showPrivacySelectionDialog, setShowPrivacySelectionDialog] =
    useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const termsAndConditions = params.get("termsAndConditions");
  const privacyPolicy = params.get("privacyPolicy");

  useEffect(() => {
    if (privacyPolicy) {
      setShowPrivacyPolicy(true);
    }
  }, [privacyPolicy]);

  useEffect(() => {
    if (termsAndConditions) {
      setShowTermsAndConditions(true);
    }
  }, [termsAndConditions]);

  /**
   * Renders the hero section of the screen.
   */
  const renderHero = () => {
    return (
      <Grid
        item
        xs={12}
        style={{
          backgroundImage: `url(${Hero})`,
          height: onlyLargeScreen ? 650 : onlySmallScreen ? 450 : 500,
          width: "100%",
          backgroundSize: "cover",
        }}
      >
        <Stack
          direction="row"
          spacing={4}
          alignItems="center"
          style={{ padding: 16 }}
        >
          <img
            alt="Bluebirding logo"
            style={{ width: 50 }}
            src={BluebirdingLogo}
          />
          <Typography variant="h6" component="h2" color="white">
            Bluebirding
          </Typography>
        </Stack>
        <Grid item xs={12} alignItems="center" textAlign="center">
          <Stack
            sx={{
              p: 9,
              [theme.breakpoints.down("md")]: {
                p: 4,
              },
            }}
            alignItems="center"
          >
            <Typography
              variant="h1"
              component="h2"
              color="white"
              sx={{
                fontWeight: "bold",
                [theme.breakpoints.down("md")]: {
                  fontSize: 42,
                },
              }}
            >
              Lease land for recreational activities
            </Typography>
            <Typography variant="h6" component="h2" color="white">
              Bluebirding makes it easy for outdoor enthusiasts to find places
              to visit anywhere
            </Typography>
            <Stack
              direction="row"
              spacing={4}
              style={{ marginTop: 16 }}
              justifyContent="center"
            >
              <img
                alt="Download Bluebirding Apple Store"
                style={{
                  width: onlyLargeScreen ? 200 : onlySmallScreen ? 150 : 175,
                  cursor: "pointer",
                }}
                src={DownloadAppleStore}
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/us/app/bluebirding/id1553837668",
                    "_blank",
                    "noreferrer"
                  )
                }
              />
              <img
                alt="Download Bluebirding Google Play Store"
                style={{
                  width: onlyLargeScreen ? 200 : onlySmallScreen ? 150 : 175,
                  cursor: "pointer",
                }}
                src={DownloadPlayStore}
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.bitzllc.bluebird",
                    "_blank",
                    "noreferrer"
                  )
                }
              />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    );
  };

  /**
   * Renders the find land section of the screen.
   */
  const renderFindLand = () => {
    return (
      <Grid
        container
        style={{ backgroundColor: "#F3F7FE" }}
        sx={{ pt: 6, pb: 6 }}
      >
        <Grid item xs={12} sm={5} textAlign="center">
          <img
            alt="Bluebirding App Map Search to Find Land"
            style={{
              height: onlyLargeScreen ? 600 : onlySmallScreen ? 450 : 550,
            }}
            src={FindLandIphone}
          />
        </Grid>
        <Grid item xs={12} sm={7} sx={{ p: 4 }}>
          <Typography
            variant="h2"
            component="h2"
            color="primary"
            sx={{ fontWeight: "bold" }}
          >
            Find Land
          </Typography>
          <Typography variant="h6" component="h2">
            Bluebirding makes it easy for outdoor enthusiasts to find places to
            visit anywhere
          </Typography>
          <Card sx={{ display: "flex", borderRadius: 3, mt: 3 }}>
            <CardMedia
              component="img"
              sx={{
                width: onlyLargeScreen ? 225 : onlySmallScreen ? 100 : 150,
                borderRadius: 3,
              }}
              image={Search}
              alt="Search a property on Bluebirding"
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 0 auto" }}>
                <Typography component="div" variant="h5">
                  Search
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Discover locations while creating a community by building
                  relationships.
                </Typography>
              </CardContent>
            </Box>
          </Card>
          <Card sx={{ display: "flex", borderRadius: 3, mt: 6 }}>
            <CardMedia
              component="img"
              sx={{
                width: onlyLargeScreen ? 225 : onlySmallScreen ? 100 : 150,
                borderRadius: 3,
              }}
              image={Book}
              alt="Book a property on Bluebirding"
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 0 auto" }}>
                <Typography component="div" variant="h5">
                  Book
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Book a trip through the Bluebirding platform in a few simple
                  steps.
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </Grid>
      </Grid>
    );
  };

  /**
   * Renders the our story section of the screen.
   */
  const renderOurStory = () => {
    return (
      <Grid container sx={{ pt: 6, pb: 6 }} textAlign="center">
        <Grid item xs={12}>
          <Typography variant="h2" component="h2" sx={{ fontWeight: "bold" }}>
            Our Story
          </Typography>
          <Typography variant="h6" component="h2" sx={{ mb: 4, p: 2 }}>
            Inspired by the untapped potential of privately owned lands, our
            founders embarked on a mission to bring people closer to the great
            outdoors. With a stroke of genius, Bluebirding was founded, an
            innovative platform that connected landowners and adventure seekers.
            This trailblazing venture allowed hosts to lease their lands for
            outdoor activities, from camping to hiking, enabling city dwellers
            to escape urban confines. Bluebirding sparked a revolution, uniting
            nature enthusiasts and cultivating a deep appreciation for the
            wilderness, one lease at a time.
          </Typography>
          <img
            alt="Bluebirding platform story"
            style={{
              height: onlyLargeScreen ? 500 : onlySmallScreen ? 200 : 300,
            }}
            src={OurStory}
          />
        </Grid>
      </Grid>
    );
  };

  /**
   * Renders the become a host section of the screen.
   */
  const renderBecomeAHost = () => {
    return (
      <Grid
        container
        style={{ backgroundColor: "#F3F7FE" }}
        sx={{ pt: 6, pb: 6 }}
      >
        <Grid item xs={12} sm={7} sx={{ p: 4 }}>
          <Typography
            variant="h2"
            component="h2"
            sx={{ fontWeight: "bold" }}
            color="primary"
          >
            Become a Host
          </Typography>
          <Typography variant="h6" component="h2">
            Unlock your land's potential and earn by hosting on our outdoor
            platform for recreational leasing.
          </Typography>
          <Card sx={{ display: "flex", borderRadius: 3, mt: 3 }}>
            <CardMedia
              component="img"
              sx={{
                width: onlyLargeScreen ? 225 : onlySmallScreen ? 100 : 150,
                borderRadius: 3,
              }}
              image={Register}
              alt="Register as a host on Bluebirding"
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 0 auto" }}>
                <Typography component="div" variant="h5">
                  Register
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Become a host on the Bluebirding platform and list your land.
                </Typography>
              </CardContent>
            </Box>
          </Card>
          <Card sx={{ display: "flex", borderRadius: 3, mt: 6 }}>
            <CardMedia
              component="img"
              sx={{
                width: onlyLargeScreen ? 225 : onlySmallScreen ? 100 : 150,
                borderRadius: 3,
              }}
              image={Book}
              alt="Book a property on Bluebirding"
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 0 auto" }}>
                <Typography component="div" variant="h5">
                  Get Paid
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Once your property is listed, you can begin earning cash
                  immediately.
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} sm={5} textAlign="center">
          <img
            alt="Bluebirding App Explore to Find Land"
            style={{
              height: onlyLargeScreen ? 600 : onlySmallScreen ? 450 : 550,
            }}
            src={BecomeHostIphone}
          />
        </Grid>
      </Grid>
    );
  };

  /**
   * Renders the advantages section of the screen.
   */
  const renderAdvantages = () => {
    return (
      <Grid
        container
        sx={{ pt: 6, pl: 4, pr: 4, pb: 12 }}
        textAlign="center"
        spacing={4}
      >
        <Grid item xs={12}>
          <Typography variant="h2" component="h2" sx={{ fontWeight: "bold" }}>
            Advantages
          </Typography>
          <Typography variant="h6" component="h2">
            The Bluebirding platform offers many benefits over other land
            leasing competitors.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <WorkspacePremiumOutlined color="primary" sx={{ fontSize: 75 }} />
          <Stack alignItems="center">
            <Typography component="div" variant="h5">
              First to market
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              Bluebirding is the first mobile platform to offer land leasing and
              hosting for outdoor enthusiasts.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4}>
          <CardMembershipOutlined color="primary" sx={{ fontSize: 75 }} />
          <Stack alignItems="center">
            <Typography component="div" variant="h5">
              No memberships
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              You only pay when you are ready to lease, otherwise you are free
              to browse as you wish.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4}>
          <MobileFriendlyOutlined color="primary" sx={{ fontSize: 75 }} />
          <Stack alignItems="center">
            <Typography component="div" variant="h5">
              Ease of Use
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              Find and lease land all from the comfort of your smart phone.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4}>
          <EnhancedEncryptionOutlined color="primary" sx={{ fontSize: 75 }} />
          <Stack alignItems="center">
            <Typography component="div" variant="h5">
              Increased Safety
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              Being aware of where members and guests are cultivates a safer
              environment.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4}>
          <PaidOutlined color="primary" sx={{ fontSize: 75 }} />
          <Stack alignItems="center">
            <Typography component="div" variant="h5">
              Host Incentive
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              Why not get paid for your land when it’s not being used? You set
              the price and rules.
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Groups2Outlined color="primary" sx={{ fontSize: 75 }} />
          <Stack alignItems="center">
            <Typography component="div" variant="h5">
              Community
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              Become a part of the growing Bluebirding platform and enjoy your
              favorite outdoor activities.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    );
  };

  /**
   * Renders the join our community section of the screen.
   */
  const renderJoinOurCommunity = () => {
    return (
      <Grid container sx={{ p: 4, backgroundColor: "#F3F7FE" }}>
        <Grid item xs={12} textAlign="center">
          <Typography
            variant="h2"
            component="h2"
            sx={{ fontWeight: "bold" }}
            color="primary"
          >
            Join our community
          </Typography>
          <Typography variant="h6" component="h2" sx={{ mb: 4 }}>
            Discover nature's playground by joining our outdoor community
            platform, connecting hosts and adventurers
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={4} sx={{ p: 3 }}>
          <Paper sx={{ p: 3, borderRadius: 3, mb: 4 }}>
            <Stack>
              {getStars()}
              <Typography component="div" variant="h5" sx={{ mt: 1 }}>
                Brad F.
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                sx={{ mt: 1 }}
              >
                Bluebirding seamlessly connects hosts and nature enthusiasts,
                providing unforgettable outdoor experiences. A must-try for
                landowners and adventurers alike!"
              </Typography>
            </Stack>
          </Paper>
          <Paper sx={{ p: 3, borderRadius: 3 }}>
            <Stack>
              {getStars()}
              <Typography component="div" variant="h5" sx={{ mt: 1 }}>
                Trenton B.
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                sx={{ mt: 1 }}
              >
                Absolutely thrilled with this outdoors platform! It's like
                having a personal guide to adventure. Seamless navigation,
                stunning locations, and endless possibilities. A must-have for
                nature enthusiasts!
              </Typography>
            </Stack>
          </Paper>
        </Grid>
        <Grid xs={12} sm={12} md={4} textAlign="center">
          <img
            alt="Bluebirding App Explore to Find Land"
            style={{
              height: onlyLargeScreen ? 600 : onlySmallScreen ? 450 : 500,
            }}
            src={ExploreIphone}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Paper sx={{ p: 3, borderRadius: 3, mb: 4 }}>
            <Stack>
              {getStars()}
              <Typography component="div" variant="h5" sx={{ mt: 1 }}>
                Colin R.
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                sx={{ mt: 1 }}
              >
                Bluebirding is a game-changer! Their platform connects adventure
                enthusiasts with breathtaking destinations, expert guides, and
                adrenaline-pumping activities. A one-stop shop for outdoor
                lovers. Highly recommended!
              </Typography>
            </Stack>
          </Paper>
          <Paper sx={{ p: 3, borderRadius: 3 }}>
            <Stack>
              {getStars()}
              <Typography component="div" variant="h5" sx={{ mt: 1 }}>
                Matt S.
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                sx={{ mt: 1 }}
              >
                Bluebirding is the ultimate outdoors companion! From hiking
                trails to camping spots, it's a one-stop platform for all your
                adventure needs. Highly recommended!
              </Typography>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    );
  };

  /**
   * Renders the stars for a review.
   */
  const getStars = () => {
    return (
      <Stack direction="row">
        <StarRate color="error" sx={{ mr: 1 }} />
        <StarRate color="error" sx={{ mr: 1 }} />
        <StarRate color="error" sx={{ mr: 1 }} />
        <StarRate color="error" sx={{ mr: 1 }} />
        <StarRate color="error" sx={{ mr: 1 }} />
      </Stack>
    );
  };

  /**
   * Renders the team section of the screen.
   */
  const renderTeam = () => {
    return (
      <Grid container sx={{ pt: 4, pl: 4, pr: 4, pb: 12 }} spacing={4}>
        <Grid item xs={12} textAlign="center">
          <Typography
            variant="h2"
            component="h2"
            sx={{ fontWeight: "bold" }}
            color="primary"
          >
            Team
          </Typography>
          <Typography variant="h6" component="h2">
            Meet the brains behind the operations.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} display="flex" justifyContent="center">
          <Card sx={{ maxWidth: 400 }}>
            <CardActionArea
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/in/maxgottlieb/",
                  "_blank",
                  "noreferrer"
                )
              }
            >
              <CardMedia
                sx={{ height: 240 }}
                image={MaxGottlieb}
                title="Chief Strategy Officer Max Gottlieb"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Max Gottlieb
                </Typography>
                <Typography gutterBottom variant="h6" component="div">
                  Chief Strategy Officer
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Hailing from Kanawha County, West Virginia, Max focuses his
                  efforts on the intersection of tech and the outdoors. He
                  brings to bear his legal background and business acumen to
                  help guide Bluebirding's development and growth. You can
                  usually find Max in a trout stream or up the holler, whether
                  on a horse or 4-wheeler.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} display="flex" justifyContent="center">
          <Card sx={{ maxWidth: 400 }}>
            <CardActionArea
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/in/pzbb/",
                  "_blank",
                  "noreferrer"
                )
              }
            >
              <CardMedia
                sx={{ height: 240 }}
                image={PeytonBallard}
                title="Chief Executive Officer Peyton Ballard"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Peyton Ballard
                </Typography>
                <Typography gutterBottom variant="h6" component="div">
                  Chief Executive Officer
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  A product of the New River Gorge in Fayetteville, West
                  Virginia, Peyton leverages his entrepreneurial skillset to
                  cultivate growth and build capacity at Bluebirding. Peyton
                  generally is found hiking the hills of West Virginia or
                  ‘stay’-cationing at his home at the beautiful Summersville
                  Lake.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} display="flex" justifyContent="center">
          <Card sx={{ maxWidth: 400 }}>
            <CardActionArea
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/in/reggie3/",
                  "_blank",
                  "noreferrer"
                )
              }
            >
              <CardMedia
                sx={{ height: 240 }}
                image={ReggieJohnson}
                title="Lead Developer Reggie Johnson"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Reggie Johnson
                </Typography>
                <Typography gutterBottom variant="h6" component="div">
                  Lead Developer
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Reggie Johnson is a talented software engineer hailing from
                  Suffolk, Virginia, who finds solace and inspiration in the
                  great outdoors. Reggie's passion for both technology and
                  nature has driven him to create innovative solutions that
                  bridge the gap between the digital world and the beauty of the
                  outdoors.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} display="flex" justifyContent="center">
          <Card sx={{ maxWidth: 400 }}>
            <CardActionArea
              onClick={() =>
                window.open(
                  "https://brandonfitzwater.com/home",
                  "_blank",
                  "noreferrer"
                )
              }
            >
              <CardMedia
                sx={{ height: 240 }}
                image={BrandonFitzwater}
                title="Chief Technology Officer Brandon Fitzwater"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Brandon Fitzwater
                </Typography>
                <Typography gutterBottom variant="h6" component="div">
                  Chief Technology Officer
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Born, raised, and residing in the Shenendoah Valley, Brandon's
                  love for Greater Appalachia has inspired him to blend his
                  passion for the outdoors with his technical expertise. With a
                  deep appreciation for natural beauty of his surroundings,
                  Brandon finds joy and inspiration through running.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    );
  };

  /**
   * Renders the footer section of the screen.
   */
  const renderFooter = () => {
    return (
      <Grid
        container
        sx={{ pt: 3, pb: 6, backgroundColor: "#1770F6" }}
        spacing={4}
      >
        <Grid item xs={12} sm={4} justifyContent="center" display="flex">
          <Stack
            direction="row"
            spacing={4}
            alignItems="center"
            style={{ padding: 16 }}
          >
            <img
              alt="Bluebirding logo"
              style={{ width: 75 }}
              src={BluebirdingLogo}
            />
            <Stack>
              <Typography variant="h6" component="h2" color="white">
                Bluebirding
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle1"
                component="div"
                color="white"
              >
                Copyright 2023
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={4} textAlign="center">
          <Typography gutterBottom variant="h6" component="div" color="white">
            Company
          </Typography>
          <Link
            href="mailto:info@bluebirding.io?subject=Bluebirding Inquiry"
            rel="noreferrer"
            target="_blank"
          >
            <Typography
              gutterBottom
              variant="subtitle1"
              component="div"
              color="white"
            >
              Contact
            </Typography>
          </Link>
          <Typography
            gutterBottom
            variant="subtitle1"
            component="div"
            color="white"
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => setShowPrivacySelectionDialog(true)}
          >
            Privacy
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} textAlign="center">
          <Typography gutterBottom variant="h6" component="div" color="white">
            Community
          </Typography>
          <Link
            href="https://wellfound.com/company/bluebirding"
            rel="noreferrer"
            target="_blank"
          >
            <Typography
              gutterBottom
              variant="subtitle1"
              component="div"
              color="white"
            >
              Angel
            </Typography>
          </Link>
        </Grid>
        <Dialog
          onClose={() => setShowPrivacySelectionDialog(false)}
          open={showPrivacySelectionDialog}
        >
          <DialogTitle>
            Privacy Policy
            <DialogContentText>
              Select one of the links below to view each policy.
            </DialogContentText>
          </DialogTitle>
          <List sx={{ pt: 0 }}>
            <ListItem disableGutters>
              <ListItemButton
                onClick={() => {
                  setShowPrivacySelectionDialog(false);
                  setShowPrivacyPolicy(true);
                }}
              >
                <ListItemText primary="Privacy Policy" />
              </ListItemButton>
            </ListItem>
            <ListItem disableGutters>
              <ListItemButton
                onClick={() => {
                  setShowPrivacySelectionDialog(false);
                  setShowTermsAndConditions(true);
                }}
              >
                <ListItemText primary="Terms and Conditions" />
              </ListItemButton>
            </ListItem>
          </List>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => setShowPrivacySelectionDialog(false)}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          fullScreen
          open={showPrivacyPolicy}
          onClose={() => setShowPrivacyPolicy(false)}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setShowPrivacyPolicy(false)}
                aria-label="close"
              >
                <Close />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Privacy Policy
              </Typography>
            </Toolbar>
          </AppBar>
          <Privacy />
        </Dialog>
        <Dialog
          fullScreen
          open={showTermsAndConditions}
          onClose={() => setShowTermsAndConditions(false)}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setShowTermsAndConditions(false)}
                aria-label="close"
              >
                <Close />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Terms and Conditions
              </Typography>
            </Toolbar>
          </AppBar>
          <Terms />
        </Dialog>
      </Grid>
    );
  };

  return (
    <Grid container>
      {renderHero()}
      {renderFindLand()}
      {renderOurStory()}
      {renderBecomeAHost()}
      {renderAdvantages()}
      {renderJoinOurCommunity()}
      {renderTeam()}
      {renderFooter()}
    </Grid>
  );
}

export default App;
